/* eslint no-useless-escape: 0 */

export const CEP = {
  mask: '#####-###',
  placeholder: '00000-000',
}

export function formatCEP(str) {
  if (!str) return ''
  return str.length === 8 ? str.replace(/(\d{5})(\d{3})/g, '$1-$2') : str
}

export const HORA = {
  mask: '##:##',
  placeholder: '00:00',
}

export function formatHora(str) {
  if (!str) return ''
  return str.length === 4 ? str.replace(/(\d{2})(\d{2})/g, '$1:$2') : str
}

export const CELLPHONE = {
  placeholder: '(00) 00000-0000',
}

export const PHONE = {
  placeholder: '(00) 0000-0000',
}

export const CNPJ = {
  mask: '##.###.###/####-##',
  placeholder: '00.000.000/0000-00',
}

export const CPF = {
  mask: '###.###.###-##',
  placeholder: '000.000.000-00',
}

export const RG = {
  mask: '##.###.###-#',
  placeholder: '00.000.000-0',
}

export const CURRENCY = {
  mask: 'R$ ###.###.###,##',
  placeholder: 'R$ 0,00',
}

/**
 * Formata a string informada para o formato 00.000.000/0000-00
 * @param {String} str valor sem mascara.
 */
export function formatCNPJ(str) {
  return str ? str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : null
}

/**
 * @description Remove formato 00.000.000/0000-00 da string informada.
 * @param {String} str valor com máscara.
 */
export function formatCNPJToString(str) {
  return str ? str.replace(/[^0-9]+/g, '') : null
}

/**
 * @description Formata a string informada para o formato 000.000.000-00
 * @param {String} strCpfWithoutMask valor sem máscara
 */
export function formatCPF(strCpfWithoutMask) {
  return strCpfWithoutMask.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

/**
 * @description Formata uma string em CNPJ ou CPF.
 * @param {String} str
 */
export function formatStrToCnpjOrCpf(str) {
  if (!str) return ''
  return str.length === 11 ? str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

/**
 * @description Válida se um CPF é válido.
 * @param {String} cpf valor com mascara.
 */
export function validateCPF(cpf) {
  cpf = removeFormat(cpf)
  var numeros, digitos, soma, i, resultado, digitos_iguais
  digitos_iguais = 1
  if (cpf.length < 11) return false
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitos_iguais = 0
      break
    }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9)
    digitos = cpf.substring(9)
    soma = 0
    for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== digitos.charAt(0)) return false
    numeros = cpf.substring(0, 10)
    soma = 0
    for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== digitos.charAt(1)) return false
    return true
  } else return false
}

/**
 * Valida CPF e CNPJ.
 *
 * @param {string} value - O valor a ser validado (deve conter 11 ou 14 dígitos).
 * @returns {boolean} - Retorna `true` se o valor for um CPF ou CNPJ válido, e `false` caso contrário.
 */
export function validarCpfCnpj(value) {
  const cpfPattern = /^\d{11}$/
  const cnpjPattern = /^\d{14}$/

  const validarCPF = (cpf) => {
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false
    let soma = 0,
      resto

    for (let i = 1; i <= 9; i++) soma += parseInt(cpf[i - 1]) * (11 - i)
    resto = (soma * 10) % 11
    if (resto === 10 || resto === 11) resto = 0
    if (resto !== parseInt(cpf[9])) return false

    soma = 0
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf[i - 1]) * (12 - i)
    resto = (soma * 10) % 11
    if (resto === 10 || resto === 11) resto = 0

    return resto === parseInt(cpf[10])
  }

  const validarCNPJ = (cnpj) => {
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    const digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado != digitos.charAt(0)) return false

    tamanho++
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    return resultado == digitos.charAt(1)
  }

  return cpfPattern.test(value) ? validarCPF(value) : cnpjPattern.test(value) ? validarCNPJ(value) : false
}

/**
 * @description Remove a formatação de um texto, retornando string sem caracteres especiais.
 * @param {String} strText
 */
export function removeFormat(strText) {
  return strText.replace(/(\.|\/|-|_)/g, '')
}

export function decodeBase64(str) {
  return decodeURIComponent(escape(atob(str)))
}

export function encodeBase64(str) {
  return btoa(unescape(encodeURIComponent(str)))
}

/**
 * @description Retorna a url base 64 padrão de uma imagem.
 */
export function defaultDataUrlImage() {
  return 'data:image/png;base64,'
}

/**
 * @description Substitui todas as strings da posição {from} até a posição {to}
 * @param {String} str
 * @param {Number} from
 * @param {Number} to
 */
export function replaceAll(str, from, to) {
  var pos = str.indexOf(from)
  while (pos > -1) {
    str = str.replace(from, to)
    pos = str.indexOf(from)
  }
  return str
}

/**
 * Gera uma chave randomicamente com o tamanho determinado no parâmetro.
 * @param {string} keyLength
 */
export function generateKey(keyLength = 10) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < keyLength; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export function customFormatPhone(value) {
  let result = value

  if (value.length <= 10) {
    result = value.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
  } else if (value.length > 10 && value.length <= 11) {
    result = value.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
  } else if (value.length > 11) {
    result = result.substring(0, 11)
  }

  return result
}

export function customFormatCnpjCpf(value) {
  let result = value

  if (value.length <= 11) {
    result = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  } else if (value.length > 11 && value.length <= 14) {
    result = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  } else if (value.length > 14) {
    result = result.substring(0, 14)
  }

  return result
}

export const alphanumeric = value => typeof value === 'string' ? value.replace(/[^a-zA-Z0-9]/g, '') : value;

export function formatDiaMes(value) {
  let formattedValue = value.replace(/\D/g, '')

  if (formattedValue.length > 2) {
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`
  }

  return formattedValue
}

export function customFormatRg(value) {
  let result = value

  if (value.length <= 9) {
    result = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
  } else if (value.length > 9) {
    result = result.substring(0, 9)
    result = result.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
  }

  return result
}

export function customNumber(value) {
  const valorOriginal = value
  return valorOriginal.replace(/\D/g, '')
}

export function extrairDocumentoFornecedor(texto) {
  const regex = /\(Doc:\s*(\d+)\)/
  const match = texto.match(regex)
  return match ? match[1] : null
}
