/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, formValueSelector, Field, arrayInsert, arrayRemove } from 'redux-form'
import MaskedFormatInput from '../../../../common/input/MaskedFormatInput'
import Input from './../../../../common/input/Input'
import Form from './../../../../common/form/Form'
import FormHeader from './../../../../common/form/FormHeader'
import FormContent from './../../../../common/form/FormContent'
import { CEP, CNPJ, CPF, RG, customFormatCnpjCpf, customFormatPhone, customFormatRg, customNumber, formatCEP } from '../../../../../utils/helpers/StringHelper'
import If from './../../../../common/If/If'
import ConfirmDialog from './../../../../common/modal/ConfirmDialog'
import CheckboxInput from './../../../../common/input/CheckboxInput'
import { showConfirmDialog } from './../../../../../actions/common/modal/ConfirmDialogAction'
import { validate } from '../validation/prestadoraFormValidation'
import {
  showPrestadoraList,
  onChangeServicoPrestado,
  changeEmailPrestadora,
  getCEP,
  limpaDependenteOnChange,
  relacionamentoClienteFornecedor,
  loadFormDocumento,
} from './../../../../../actions/prestadora/PrestadoraAction'
import Button from '../../../../common/button/Button'
import { validacaoEmail } from '../../../../../utils/helpers/EmailHelper'
import DropdownListInput from '../../../../common/input/DropdownListInput'
import MultSelectListInput from '../../../../common/input/MultSelectListInput'
import FixedFloaterErrors from '../../../../common/floats/FixedFloaterErrors'
import { withRouter } from 'react-router-dom'
import { msgError } from '../../../../../utils/helpers/OthersHelpers'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { withStyles } from '@material-ui/core/styles'
import SwitchField from '../../../../common/input/SwitchField'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const styles = (theme) => ({
  parent: {
    width: '100%',
    marginTop: '0.5%',
    backgroundColor: 'transparent',
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: '#630A37',
    '&:disabled': {
      color: '#D0D0D0',
    },
  },
  buttons: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '1rem !important',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: 'flex',
      justifyContent: 'center',
    },
    "& [class*='MuiTypography-root']": {
      width: 'max-content',
      padding: '0 10px',
      borderRadius: '20px',
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: '#B8B8B8',
        color: '#121212c9',
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: '#FFC107',
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: '#83BF74',
        color: '#ffffff',
        '&.revisao': {
          backgroundColor: '#cc0000',
        },
      },
    },
  },
})
class PrestadoraForm extends Component {
  constructor(props) {
    super(props)

    this.state = { emailPrestadora: '' }
  }

  addConta(index) {
    if (!this.props.view) {
      this.props.arrayInsert('prestadoraForm', 'empresaPrestadora.contas', index, {})
    }
  }

  removeConta(index) {
    if (!this.props.view && this.props.contas.length > 0) {
      this.props.arrayRemove('prestadoraForm', 'empresaPrestadora.contas', index)
    }
  }

  render() {
    const {
      empresaPrestadora,
      title,
      handleSubmit,
      readOnly,
      view,
      showPrestadoraList,
      servicosPrestados,
      servicosPrestadosSelecionados,
      floatButtonVisible,
      showConfirmDialog,
      showCancelConfirmDialog,
      onChangeServicoPrestado,
      filter,
      changeEmailPrestadora,
      getCEP,
      personalidade,
      pais,
      statusCompliance,
      messages,
      limpaDependenteOnChange,
      paisList,
      statusComplianceList,
      personalidadesList,
      estadosList,
      cnaeList,
      relacionamentoClienteFornecedor,
      contas,
      bancoList,
      invalid,
      classes,
      //
      documento,
      loadFormDocumento,
      emitirNotaFiscal,
      realizarPagamento,
      estadoCivilList,
      perfilPessoaList,
      dadosInterno,
      perfisOriginal,
    } = this.props

    let renderStatus = [
      {
        key: 1,
        value: 'Verificado',
        raw: 'Verificado',
        show: true,
        concluidos: [1],
      },
      {
        key: 2,
        value: 'Cadastrado',
        raw: 'Cadastrado',
        show: true,
        concluidos: [1, 2],
      },
      {
        key: 3,
        value: 'Registrado',
        raw: 'Registrado',
        show: true,
        concluidos: [1, 2, 3],
      },
    ]

    const statusValue = empresaPrestadora?.statusAprovacao?.key

    const user = JSON.parse(localStorage.getItem('userLogged'))

    let isAdmin = view ? false : user.acessos.some((acesso) => acesso === 1)
    let isSpecOrManager = view ? false : user.acessos.some((acesso) => acesso === 2 || acesso === 3)
    let isConsOrSeg = view ? false : user.acessos.some((acesso) => acesso === 4 || acesso === 5)

    const notCons = user.acessos.some((acesso) => acesso !== 4)

    const areas = user.areasAtuacoes.map((ar) => ar.id)
    const OPE_TES = areas.some((area) => [9, 8].includes(area))
    const PDIGIT = areas.some((area) => [12].includes(area))

    const fullTeasury = OPE_TES && notCons && !isConsOrSeg
    const isRegistered = isSpecOrManager || isAdmin

    const emissorSelected = servicosPrestadosSelecionados.some((sp) => sp.descricao == 'Emissor')
    const bancoSelected = servicosPrestadosSelecionados.some((sp) => sp.descricao == 'Banco')

    const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value)
    const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos)

    function CustomStepIcon({ active, completed, classes }) {
      const cComp = '#83BF74'
      const cActv = '#FFC107'
      const cDflt = '#888888'

      return completed ? (
        <CheckCircleOutline style={{ color: cComp }} />
      ) : active ? (
        <RadioButtonChecked style={{ color: cActv }} />
      ) : (
        <RadioButtonUnchecked style={{ color: cDflt }} />
      )
    }

    //

    const perfisTagList = document.querySelectorAll('#perfis_taglist > li')
    if (perfisTagList.length && dadosInterno) {
      perfisTagList.forEach((li) => {
        const spanValue = li.querySelector('span').textContent?.trim()
        const perfilEncontrado = perfisOriginal?.find((perfil) => perfil.value === spanValue)
        const buttonDelete = li.querySelector('div > button')

        if (perfilEncontrado && buttonDelete) buttonDelete.remove()
      })
    }

    return (
      <Form>
        <Stepper className="mt-4" classes={{ root: classes.stepper }} activeStep={concluidos.length || 0} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index} completed={concluidos.some((et) => et === index + 1)}>
              <StepLabel
                completed={concluidos.some((et) => et === index + 1)}
                className={classes.customStepLabel}
                StepIconComponent={CustomStepIcon}
                classes={{
                  completed: `${classes.completed}`,
                }}
                StepIconProps={{
                  classes: {
                    completed: `${classes.completed}`,
                    active: classes.active,
                    disabled: classes.disabled,
                  },
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <FormHeader title={title} col={'col-lg'} offSet={20}></FormHeader>
        <FormContent handleSubmit={handleSubmit}>
          <div className="row">
            {messages?.hasError && <FixedFloaterErrors messages={messages} title="Status do Cadastro" />}

            {statusValue ? (
              <>
                {/* Dados Cadastrais */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Dados Cadastrais</span>
                    <div className="row">
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="personalidade"
                          name="personalidade"
                          label="Personalidade"
                          options={personalidadesList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={true}
                          required
                          onChange={() => {
                            limpaDependenteOnChange('empresaPrestadora.idCnae')
                            limpaDependenteOnChange('empresaPrestadora.cnpj')
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          label={!personalidade?.key ? 'Documento' : personalidade?.key == 1 ? 'CNPJ' : 'CPF'}
                          inputId="cnpj"
                          maxLength={!personalidade?.key ? '0' : personalidade?.key == 1 ? '18' : '14'}
                          readOnly={true}
                          className={'filter-input'}
                          name="empresaPrestadora.cnpj"
                          component={MaskedFormatInput}
                          placeholder={!personalidade?.key ? null : personalidade?.key == 1 ? CNPJ.placeholder : CPF.placeholder}
                          formatMask={customFormatCnpjCpf}
                          required
                        />
                      </div>
                      {personalidade?.key == 2 && (
                        <>
                          <div className="col-lg">
                            <Field
                              label="RG"
                              inputId="rg"
                              maxLength={12}
                              readOnly={readOnly || dadosInterno}
                              className={'filter-input'}
                              name="empresaPrestadora.rg"
                              component={MaskedFormatInput}
                              placeholder={RG.placeholder}
                              formatMask={customFormatRg}
                              required
                            />
                          </div>

                          <div className="col-lg">
                            <Field
                              component={Input}
                              inputId="nomeExibicao"
                              name="empresaPrestadora.nomeSimplificado"
                              label="Nome Exibição"
                              readOnly={readOnly || dadosInterno}
                              required
                            />
                          </div>
                        </>
                      )}
                      {personalidade?.key == 1 && (
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="razaoSocial"
                            name="empresaPrestadora.razaoSocial"
                            label="Razão Social"
                            readOnly={readOnly || dadosInterno || isConsOrSeg}
                            required
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {personalidade?.key == 1 && (
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="nomeFantasia"
                            name="empresaPrestadora.nomeSimplificado"
                            label="Nome Fantasia"
                            readOnly={readOnly || dadosInterno || isConsOrSeg}
                            required
                          />
                        </div>
                      )}

                      {personalidade?.key == 2 && (
                        <div className="col-lg-3">
                          <Field
                            component={DropdownListInput}
                            inputId="estadoCivil"
                            name="empresaPrestadora.estadoCivil"
                            label="Estado Civil"
                            options={estadoCivilList}
                            textField="value"
                            valueField="key"
                            filter="contains"
                            readOnly={readOnly || dadosInterno}
                            required
                          />
                        </div>
                      )}

                      <div className="col-lg-3">
                        <Field
                          required
                          label="Telefone"
                          inputId="telefone"
                          name="empresaPrestadora.telefone"
                          maxLength="15"
                          readOnly={readOnly || isConsOrSeg}
                          component={MaskedFormatInput}
                          formatMask={customFormatPhone}
                        />
                      </div>

                      <div className="col-lg-6">
                        <Field
                          component={MultSelectListInput}
                          inputId="perfis"
                          name="empresaPrestadora.perfis"
                          label="Perfil"
                          fixedItems={dadosInterno ? perfisOriginal : []}
                          options={perfilPessoaList}
                          dataKey="key"
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || isConsOrSeg}
                          required
                          outerClassName={(readOnly || isConsOrSeg) && 'disabled-field'}
                          className={`${(readOnly || isConsOrSeg) && 'clear-inside'} mb-0`}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg">
                        <Field component={Input} inputId="emailPessoa" name="empresaPrestadora.emailPessoa" label="E-mail" type="email" readOnly={readOnly || isConsOrSeg} />
                      </div>
                      <div className="col-lg-2">
                        <Field
                          name="empresaPrestadora.emitirNotaFiscal"
                          component={SwitchField}
                          textSide={emitirNotaFiscal ? 'Sim' : 'Não'}
                          labelUp="Emitiremos Nota Fiscal?"
                          labelUpStyle={{ top: '-12px' }}
                          classNameOut="form-group field-floating has-float-label d-flex flex-column align-items-start"
                          classTextSide="ml-2 text-nowrap"
                          classContentSwitch="d-flex flex-row align-items-center mt-2"
                          width={45}
                          height={20}
                          uncheckedOffset={15}
                          checkedOffset={15}
                          onColor="#900E37"
                          offColor="#BFBFBF"
                          disabled={readOnly || (statusValue !== 1 && !isAdmin && !(statusValue == 3 && isRegistered))}
                          onChange={async () => {
                            await relacionamentoClienteFornecedor()
                          }}
                        />
                      </div>
                      <div className="col-lg-2">
                        <Field
                          name="empresaPrestadora.realizarPagamento"
                          component={SwitchField}
                          textSide={realizarPagamento ? 'Sim' : 'Não'}
                          labelUp="Realizaremos Pagamento?"
                          labelUpStyle={{ top: '-12px' }}
                          classNameOut="form-group field-floating has-float-label d-flex flex-column align-items-start"
                          classTextSide="ml-2 text-nowrap"
                          classContentSwitch="d-flex flex-row align-items-center mt-2"
                          width={45}
                          height={20}
                          uncheckedOffset={15}
                          checkedOffset={15}
                          onColor="#900E37"
                          offColor="#BFBFBF"
                          disabled={readOnly || (statusValue !== 1 && !isAdmin && !(statusValue == 3 && isRegistered))}
                          onChange={async () => {
                            await relacionamentoClienteFornecedor()
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {personalidade?.key == 1 && (
                        <div className="col-lg">
                          <Field
                            component={DropdownListInput}
                            options={cnaeList}
                            inputId="idCnae"
                            name="empresaPrestadora.idCnae"
                            type="text"
                            label="CNAE"
                            textField="descricao"
                            valueField="id"
                            filter="contains"
                            className={'filter-input'}
                            readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                            required
                          />
                        </div>
                      )}

                      {emissorSelected && isAdmin && (
                        <div className="col-lg-2">
                          <Field
                            component={Input}
                            className={'filter-input'}
                            inputId="sigla"
                            name="empresaPrestadora.sigla"
                            maxLength={5}
                            type="text"
                            label="Sigla"
                            readOnly={readOnly && !isAdmin}
                            required
                          />
                        </div>
                      )}

                      {bancoSelected && isAdmin && (
                        <div className="col-lg-2">
                          <Field
                            component={Input}
                            className={'filter-input'}
                            inputId="codigoBanco"
                            name="empresaPrestadora.codigo"
                            maxLength={5}
                            type="number"
                            label="Código Banco"
                            readOnly={readOnly && !isAdmin}
                            required
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Endereço */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Endereço</span>
                    <div className="row">
                      <div className={`col-lg-${pais?.key == 55 ? '2' : '3'}`}>
                        <Field
                          component={DropdownListInput}
                          inputId="pais"
                          name="endereco.pais"
                          label="País"
                          options={paisList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                          onChange={() => limpaDependenteOnChange('endereco.cep')}
                        />
                      </div>

                      {pais?.key == 55 && (
                        <div className="col-lg-2">
                          <Field
                            inputId="cep"
                            name="endereco.cep"
                            type="text"
                            label="CEP"
                            maxLength={9}
                            readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                            formatMask={formatCEP}
                            component={MaskedFormatInput}
                            placeholder={CEP.placeholder}
                            onChange={(e) => getCEP(e.target.value, estadosList)}
                            required
                          />
                        </div>
                      )}

                      <div className="col-lg">
                        <Field
                          component={Input}
                          inputId="endereco"
                          name="endereco.endereco"
                          type="text"
                          label="Endereço"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                        />
                      </div>
                      <div className="col-lg-2">
                        <Field
                          component={Input}
                          inputId="numero"
                          name="endereco.numero"
                          type="text"
                          label="Número"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4">
                        <Field
                          component={Input}
                          inputId="complemento"
                          name="endereco.complemento"
                          type="text"
                          label="Complemento"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={Input}
                          inputId="bairro"
                          name="endereco.bairro"
                          type="text"
                          label="Bairro"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={Input}
                          inputId="cidade"
                          name="endereco.cidade"
                          type="text"
                          label="Cidade"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                        />
                      </div>
                      <div className="col-lg-2">
                        <Field
                          component={DropdownListInput}
                          options={estadosList}
                          inputId="estado"
                          name="endereco.estado"
                          type="text"
                          textField="raw"
                          valueField="raw"
                          filter="contains"
                          label="Estado"
                          readOnly={dadosInterno || (statusValue == 1 && !isRegistered) || statusValue == 2}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Contas */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <div className="column">
                      <span className="page-head__title h3 mb-3">Contas</span>
                      <div className="d-flex flex-column w-100">
                        <div className="col more-fields p-0">
                          <TransitionGroup>
                            {contas.map((item, index) => {
                              const rOnly = Boolean(contas[index].id) && !isAdmin && !(statusValue == 3 && isRegistered)

                              return (
                                <CSSTransition key={index} timeout={300} classNames="item">
                                  <div key={index} className="more-fields-item">
                                    <div className="row d-flex">
                                      <div className={`row col-12 col-lg pr-0 ${!rOnly ? 'pr-lg-3' : ''}`}>
                                        <div className="pr-0 pr-lg-3 col-12 col-lg-4">
                                          <Field
                                            component={DropdownListInput}
                                            inputId="banco"
                                            name={`empresaPrestadora.contas[${index}].idBancoExterno`}
                                            label="Banco"
                                            options={bancoList}
                                            textField="nome"
                                            valueField="id"
                                            filter="contains"
                                            readOnly={rOnly}
                                            outerClassName={rOnly && 'disabled-field'}
                                            required
                                          />
                                        </div>
                                        <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                          <Field
                                            component={Input}
                                            inputId="agencia"
                                            name={`empresaPrestadora.contas[${index}].agencia`}
                                            type="text"
                                            label="Agência"
                                            readOnly={rOnly}
                                            outerClassName={rOnly && 'disabled-field'}
                                            required
                                          />
                                        </div>
                                        <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                          <Field
                                            component={Input}
                                            inputId="agenciaDigito"
                                            name={`empresaPrestadora.contas[${index}].agenciaDigito`}
                                            type="text"
                                            label="Agência Dígito"
                                            readOnly={rOnly}
                                            outerClassName={rOnly && 'disabled-field'}
                                          />
                                        </div>
                                        <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                          <Field
                                            component={Input}
                                            inputId="conta"
                                            name={`empresaPrestadora.contas[${index}].conta`}
                                            type="text"
                                            label="Conta"
                                            readOnly={rOnly}
                                            outerClassName={rOnly && 'disabled-field'}
                                            required
                                          />
                                        </div>
                                        <div className={`pr-0 ${!rOnly ? 'pr-lg-3' : ''} col-12 col-lg-2`}>
                                          <Field
                                            component={Input}
                                            inputId="contaDigito"
                                            name={`empresaPrestadora.contas[${index}].contaDigito`}
                                            type="text"
                                            label="Conta Dígito"
                                            maxLength="3"
                                            readOnly={rOnly}
                                            outerClassName={rOnly && 'disabled-field'}
                                            required
                                          />
                                        </div>
                                      </div>

                                      {!rOnly && (
                                        <div className="col-12 col-lg-1 d-flex align-items-center">
                                          <Button
                                            classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                            activeClassName={'col-lg-auto p-0'}
                                            buttonStyle={'success'}
                                            action={() => this.removeConta(index)}
                                            icon={'trash'}
                                            name={'Remover'}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </CSSTransition>
                              )
                            })}
                          </TransitionGroup>
                        </div>
                        <If test={!view && (isAdmin || isSpecOrManager)}>
                          <div className="mx-auto mt-3">
                            <Button
                              classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                              activeClassName={'col-lg-auto p-0'}
                              buttonStyle={'success'}
                              action={() => this.addConta(contas.length)}
                              icon={'plus'}
                              name={'Adicionar'}
                            />
                          </div>
                        </If>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Dados do Compliance */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Dados do Compliance</span>
                    <div className="row">
                      <div className="col-lg-2">
                        <Field
                          inputId="statusCompliance"
                          name="complemento.statusCompliance"
                          component={DropdownListInput}
                          options={statusComplianceList}
                          label="Análise Compliance"
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || isConsOrSeg}
                          required
                        />
                      </div>
                      <div className="col-lg-10">
                        <Field
                          inputId="comentarioCompliance"
                          name="complemento.comentarioCompliance"
                          component={Input}
                          type="text"
                          label="Comentário"
                          readOnly={readOnly || isConsOrSeg}
                          icon="info-circle"
                          iconColor="#630a3679"
                          tooltipMessage="Necessário justificar os cadastros reprovados pelo Compliance ou as pessoas que não tiveram análise pelo Compliance realizada."
                          tooltipId="commt"
                          required={statusCompliance?.key !== 2}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Dados do Protheus */}
                {statusValue >= 2 && (
                  <div className="col-12">
                    <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                      <span className="h3 page-head__title">Dados do Protheus</span>
                      <div className="row">
                        <div className="col-lg-3">
                          <Field
                            inputId="lojaProtheus"
                            name="complemento.lojaProtheus"
                            type="text"
                            component={MaskedFormatInput}
                            formatMask={customNumber}
                            label="Loja"
                            readOnly={true}
                          />
                        </div>
                        <div className="col-lg-3">
                          <Field component={Input} inputId="relacionamentoProtheus" name="complemento.relacionamentoProtheus" type="text" label="Relacionamento" readOnly={true} />
                        </div>
                        <div className="col-lg-3">
                          <Field
                            inputId="codigoClienteProtheus"
                            name="complemento.codigoClienteProtheus"
                            maxLength={6}
                            type="text"
                            component={MaskedFormatInput}
                            formatMask={customNumber}
                            label="Código Cliente"
                            readOnly={readOnly && !fullTeasury && !(PDIGIT && isAdmin)}
                            icon="info-circle"
                            iconColor="#630a3679"
                            tooltipMessage="Código do Cliente no Protheus (dado com 6 dígitos)"
                            tooltipId="codCli"
                            required={emitirNotaFiscal}
                          />
                        </div>
                        <div className="col-lg-3">
                          <Field
                            inputId="codigoFornecedorProtheus"
                            name="complemento.codigoFornecedorProtheus"
                            maxLength={6}
                            type="text"
                            component={MaskedFormatInput}
                            formatMask={customNumber}
                            label="Código Fornecedor"
                            readOnly={readOnly && !fullTeasury && !(PDIGIT && isAdmin)}
                            icon="info-circle"
                            iconColor="#630a3679"
                            tooltipMessage="Código do Fornecedor no Protheus (dado com 6 dígitos)"
                            tooltipId="codFor"
                            required={realizarPagamento}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Serviços Prestados */}
                {isAdmin && (
                  <>
                    {servicosPrestados.length > 0 && (
                      <div className="col-12">
                        <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                          <span className="h3 page-head__title">Serviços Prestados</span>
                          <div className="row col-lg-12 px-0">
                            {servicosPrestados.map((servico, index) => {
                              return (
                                <div className="col-lg-4" key={index}>
                                  <Field
                                    index={index}
                                    component={CheckboxInput}
                                    inputId={servico.id}
                                    name={`empresaPrestadora.tipoServicoPrestadoList[${index}]`}
                                    disabled={readOnly && !isAdmin}
                                    label={servico.descricao}
                                    onChange={(e) => onChangeServicoPrestado(e, index)}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )}

                    {servicosPrestadosSelecionados.length > 0 && (
                      <div className="col-12">
                        <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                          <span className="h3 page-head__title">E-mails dos Serviços Prestados</span>
                          <div className="col-lg-12 my-3 px-0">
                            <TransitionGroup>
                              {servicosPrestadosSelecionados.map((servico, index) => {
                                return (
                                  <CSSTransition key={index} timeout={300} classNames="item">
                                    <div key={index}>
                                      {!readOnly || (readOnly && isAdmin) ? (
                                        <div className="row align-items-center">
                                          <div className="col-xl-8 col-12">
                                            <div className="form-group field-floating has-float-label">
                                              <input
                                                type="email"
                                                autoComplete="off"
                                                name={`name_${servico.descricao}`}
                                                id={`id_emails_${servico.descricao}`}
                                                onChange={(e) => this.setState({ emailPrestadora: e.target.value })}
                                                readOnly={readOnly && !isAdmin}
                                                className={`form-control ${readOnly && !isAdmin && 'blocked'}`}
                                              />
                                              <label htmlFor={`name_${servico.descricao}`} className="control-label">
                                                {`E-mails ${servico.descricao}`}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-xl-2 col-4 pl-xl-0">
                                            <Button
                                              icon={'plus'}
                                              name={'Adicionar'}
                                              nameClass="f-14 text-capitalize"
                                              action={() => {
                                                const arrEmails = servico.tipoServicoPrestadoEmpresaEmails

                                                changeEmailPrestadora(true, validacaoEmail(this.state.emailPrestadora, arrEmails), index)
                                                this.setState({ emailPrestadora: '' })

                                                const elements = document.querySelectorAll('[id*="id_emails_"]')

                                                elements.forEach((el) => (el.value = ''))
                                              }}
                                              classes="bg-white brd-p-color-imp d-flex align-items-center p-color-imp rounded btn-search h-38 px-3 m-0 mt-xl-2 shadow-none mb-xl-0 mb-2"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <p className="mb-0 txt-grey f-12">{`E-mails ${servico.descricao}`}</p>
                                      )}

                                      <div className="row align-items-center px-3">
                                        <div className="d-flex flex-wrap mb-2">
                                          <TransitionGroup className={'d-flex flex-wrap'}>
                                            {servico.tipoServicoPrestadoEmpresaEmails.map((email, i) => {
                                              return (
                                                <CSSTransition key={i} timeout={300} classNames="item">
                                                  <div key={i} className="mr-2 rounded-pill d-flex flex-row text-nowrap px-2 py-1 bg-df-gray align-items-center mb-2">
                                                    <label className="mr-2 p-color-imp m-0 f-12">{email}</label>
                                                    {(!readOnly || (readOnly && isAdmin)) && (
                                                      <i role="button" className="fa fa-trash text-danger" onClick={() => changeEmailPrestadora(false, email, index)} />
                                                    )}
                                                  </div>
                                                </CSSTransition>
                                              )
                                            })}
                                          </TransitionGroup>
                                        </div>
                                      </div>
                                    </div>
                                  </CSSTransition>
                                )
                              })}
                            </TransitionGroup>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Dados da Pessoa</span>
                    <div className="row">
                      <div className="col-lg-12">
                        <Field
                          required
                          inputId="cnpj"
                          maxLength={18}
                          name="documento"
                          className={'filter-input'}
                          component={MaskedFormatInput}
                          formatMask={customFormatCnpjCpf}
                          placeholder={`${CPF.placeholder} ou ${CNPJ.placeholder}`}
                          label={documento?.length == 18 ? 'CNPJ' : documento?.length == 14 ? 'CPF' : 'CPF/CNPJ'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {statusValue ? (
            <>
              <div className="col-12 px-0">
                <div className="d-flex flex-row justify-content-around align-items-center col-12 mb-4 p-0">
                  <Button
                    name={`Cancelar ${floatButtonVisible ? 'Alterações' : ''}`}
                    classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    action={() => {
                      floatButtonVisible ? showConfirmDialog(true) : showPrestadoraList(filter)
                    }}
                  />

                  {((!view && statusValue == 1) || statusValue >= 2) && ( // (readOnly && statusValue >= 2 && fullTeasury)
                    <Button
                      type="submit"
                      name={'Salvar Cadastro'}
                      classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                      action={() => {
                        limpaDependenteOnChange('typeSubmit', 'Salvar')
                        limpaDependenteOnChange('enviarEmail', false)
                        limpaDependenteOnChange('confirmarRegistro', false)
                        limpaDependenteOnChange('statusValue', statusValue)

                        if (invalid) {
                          msgError('Alguns erros foram encontrados. Por favor, revise atentamente todos os campos.')
                        }
                      }}
                    />
                  )}

                  {!view && statusValue == 1 && (
                    <Button
                      type="submit"
                      name={'Enviar para Registro'}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                      action={() => {
                        limpaDependenteOnChange('typeSubmit', 'Registrar')
                        limpaDependenteOnChange('enviarEmail', true)
                        limpaDependenteOnChange('confirmarRegistro', false)
                        limpaDependenteOnChange('statusValue', statusValue)

                        if (invalid) {
                          msgError('Alguns erros foram encontrados. Por favor, revise atentamente todos os campos.')
                        }
                      }}
                    />
                  )}

                  {!view && statusValue == 2 && (
                    <Button
                      type="submit"
                      name={'Confirmar Registro'}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                      action={() => {
                        limpaDependenteOnChange('typeSubmit', 'Confirmar')
                        limpaDependenteOnChange('enviarEmail', false)
                        limpaDependenteOnChange('confirmarRegistro', true)
                        limpaDependenteOnChange('statusValue', statusValue)

                        if (invalid) {
                          msgError('Alguns erros foram encontrados. Por favor, revise atentamente todos os campos.')
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 px-0">
                <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 p-0">
                  <Button
                    name="Cancelar Cadastro"
                    classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    action={() => showPrestadoraList(filter)}
                  />

                  <Button
                    name={'Verificar Cadastro'}
                    classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    action={() => {
                      if (documento?.length !== 14 && documento?.length !== 18) {
                        msgError('Documento inválido, por favor, revise o campo atentamente')
                        return false
                      }

                      loadFormDocumento(documento?.replace(/\D/g, ''), filter)
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </FormContent>
        <ConfirmDialog
          title="Aviso"
          contentText="Os dados do formulário serão perdidos, deseja realmente sair ?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => showPrestadoraList(filter)}
          type="warning"
          maxWidth="md"
        />
      </Form>
    )
  }
}

const selector = formValueSelector('prestadoraForm')

const mapStateToProps = (state) => ({
  empresaPrestadora: state.prestadoraReducer.prestadoraFormTypes.empresaPrestadora || [],
  paisList: state.prestadoraReducer.prestadoraFormTypes.paises || [],
  statusComplianceList: state.prestadoraReducer.prestadoraFormTypes.statusCompliance || [],
  personalidadesList: state.prestadoraReducer.prestadoraFormTypes.personalidades || [],
  estadosList: state.prestadoraReducer.prestadoraFormTypes.estados || [],
  cnaeList: state.prestadoraReducer.prestadoraFormTypes.cnaes || [],
  bancoList: state.prestadoraReducer.prestadoraFormTypes.bancos || [],
  contas: selector(state, 'empresaPrestadora.contas') || [],

  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  messages: state.prestadoraReducer.messages || [],
  servicosPrestados: state.prestadoraReducer.prestadoraFormTypes.servicosPrestados || [],
  emailsPrestadora: selector(state, 'empresaPrestadora.email'),

  personalidade: selector(state, 'personalidade'),
  pais: selector(state, 'endereco.pais'),
  statusCompliance: selector(state, 'complemento.statusCompliance'),

  cnpj: selector(state, 'empresaPrestadora.cnpj'),
  id: selector(state, 'empresaPrestadora.id'),
  servicosPrestadosSelecionados: selector(state, 'empresaPrestadora.tiposServicosPrestadosSelecionados') || [],
  filter: selector(state, 'filter'),
  //
  documento: selector(state, 'documento'),
  emitirNotaFiscal: selector(state, 'empresaPrestadora.emitirNotaFiscal'),
  realizarPagamento: selector(state, 'empresaPrestadora.realizarPagamento'),
  dadosInterno: selector(state, 'empresaPrestadora.dadosInterno') || false,
  perfisOriginal: selector(state, 'empresaPrestadora.perfisOriginal'),
  estadoCivilList: state.prestadoraReducer.prestadoraFormTypes.estadoCivilList || [],
  perfilPessoaList: state.prestadoraReducer.prestadoraFormTypes.perfilPessoaList || [],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showPrestadoraList,
      showConfirmDialog,
      onChangeServicoPrestado,
      changeEmailPrestadora,
      limpaDependenteOnChange,
      getCEP,
      relacionamentoClienteFornecedor,
      arrayInsert,
      arrayRemove,
      loadFormDocumento,
    },
    dispatch
  )

const ConnectedPrestadoraForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrestadoraForm)))
export default reduxForm({ form: 'prestadoraForm', validate })(ConnectedPrestadoraForm)
