/* eslint eqeqeq: 0 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, formValueSelector } from 'redux-form'
import 'moment/locale/pt-br'
import Input from '../../../../../common/input/Input'
import Textarea from '../../../../../common/input/Textarea'
import { reduxForm } from 'redux-form'
import {
  obterPreenchimentoTelaAlteracaoDiarioBordo,
  DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED,
  buscarAnexoDiariodeBordoPorId,
  changeValueForm,
  onChangeFieldInput,
} from './../../../../../../actions/gestao/DiarioBordoAction'
import DropdownListInput from '../../../../../common/input/DropdownListInput'
import Grid from '@material-ui/core/Grid'
import '../assets/CadastroEventosDiarioBordoForm.css'
import GenericPickerInput from '../../../../../common/input/GenericPickerInput'
import TextField from '@material-ui/core/TextField'
import { getOperacoes, getOperacoesFilter } from '../../../../../../actions/operacao/OperacaoAction'
import MaskedInput from '../../../../../common/input/MaskedInput'
import { caracterDiferenteZero } from './../../../../../../utils/helpers/NumberHelper'
import {
  formatacaoDoTituloConformeTiposEscolhidos,
  formatacaoDoTituloTipoContrato,
  formataTituloDeAcordoComDataOcorrencia,
  formataTituloDeAcordoComDataBase,
  formataTituloDeAcordoComTipoEvento,
  digitacaoTextoLivre,
} from '../../../../../../actions/gestao/DiarioBordoAction'
import { convertFileToBase64 } from '../../../../../../utils/helpers/FileHelper'
import { useDropzone } from 'react-dropzone'
import { msgError } from '../../../../../../utils/helpers/OthersHelpers'
import Button from '../../../../../common/button/Button'

const TIPO_EVENTO_ESPECIE_ASSEMBLEIA = 1
const TIPO_EVENTO_CONTRATO_ADITAMENTO = 2
const TIPO_EVENTO_ESPECIE_COMUNICADO = 3
const TIPO_EVENTO_HISTORICO = 7
const TIPO_EVENTO_PERFOMANCE_LASTRO = 9

let CadastroEventosDiarioBordoForm = (props) => {
  const {
    handleSubmit,
    tiposEventos,
    eventoGenerico,
    justView,
    tipoEvento,
    tipoEspecieAssembleiaLista,
    tipoEspecieComunicadoLista,
    tipoContratoAditamentoLista,
    formatacaoDoTituloConformeTiposEscolhidos,
    formatacaoDoTituloTipoContrato,
    formataTituloDeAcordoComDataOcorrencia,
    formataTituloDeAcordoComDataBase,
    formataTituloDeAcordoComTipoEvento,
    digitacaoTextoLivre,
    conteudoAnexoList,
    buscarAnexoDiariodeBordoPorId,
    changeValueForm,
    onChangeFieldInput,
  } = props

  React.useEffect(() => {
    if (props.diarioBordoAtual && props.diarioBordoAtual.id > 0) {
      props.obterPreenchimentoTelaAlteracaoDiarioBordo(props.diarioBordoAtual.id)
    }
  }, [props.diarioBordoAtual])

  const [isDragging, setIsDragging] = React.useState(false)

  const SeNaoTiverZeroPodeFormarOTituloDoContrato = (numeroOrdemContrato) => {
    return caracterDiferenteZero ? numeroOrdemContrato : null
  }

  const tiposEventosSemGestaoFiduciaria = (tipoEventoList) => {
    let tipoEventoListSemGestaoFiduciaria = tipoEventoList.filter((obj) => {
      return obj.id !== 8
    })

    return tipoEventoListSemGestaoFiduciaria
  }

  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles

    let arquivos = await Promise.all(
      files.map(async (file) => ({
        name: file.name,
        arquivo: await convertFileToBase64(file),
      }))
    )

    changeValueForm('diarioBordoAnexoList', [...conteudoAnexoList, ...arquivos])
    setIsDragging(false)
  }

  const onDelete = (index) => {
    const newFiles = [...conteudoAnexoList]
    newFiles.splice(index, 1)
    changeValueForm('diarioBordoAnexoList', newFiles)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name)
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`))
    },
    disabled: justView,
  })

  const condicaoEspecieAssembleia = tipoEvento && tipoEvento.id === TIPO_EVENTO_ESPECIE_ASSEMBLEIA
  const condicaoEspecieComunicado = tipoEvento && tipoEvento.id === TIPO_EVENTO_ESPECIE_COMUNICADO
  const condicaoContratoAditamento = tipoEvento && tipoEvento.id === TIPO_EVENTO_CONTRATO_ADITAMENTO
  const condicaoPerformanceLastroEGarantia = tipoEvento && tipoEvento.id === TIPO_EVENTO_PERFOMANCE_LASTRO
  const tituloPreenchimentoTextoLivre = tipoEvento && tipoEvento.id === TIPO_EVENTO_HISTORICO

  const campoEspecieComunicadoAssembleia = condicaoEspecieAssembleia || condicaoEspecieComunicado
  let campoFormulario = ''
  let listOptions = []
  if (condicaoEspecieAssembleia) {
    campoFormulario = 'tipoEspecieAssembleia'
    listOptions = tipoEspecieAssembleiaLista
  } else if (condicaoEspecieComunicado) {
    campoFormulario = 'tipoEspecieComunicado'
    listOptions = tipoEspecieComunicadoLista
  }

  return (
    <div className="row mx-0 logbook-register-events">
      <div className="col px-0">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid xs={12} item>
              <div className="row">
                <div className={'col mt-6px ' + (props.isEdit ? 'disabled mt-1' : '')}>
                  {!props.isEdit && (
                    <>
                      <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">Operações</label>
                      <GenericPickerInput
                        isClearable
                        id={'operacao'}
                        loadData={props.getOperacoesFilter}
                        options={props.options}
                        formName={'cadastroDiarioBordoForm'}
                        fieldName={'operacao'}
                        placeholder={'Pesquisar operação ex.:CRI.1.SEC.2'}
                        loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                        noOptionsMessage="Nenhum registro encontrado"
                        value={props.operacao}
                        selectedOptionForm={(v) => onChangeFieldInput(v, 'cadastroDiarioBordoForm', 'operacao')}
                        reducerName={DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED}
                      />
                    </>
                  )}
                  {props.isEdit && (
                    <Field component={Input} className={'filter-input'} inputId="operacao.value" name="operacao.value" type="text" label="Operação" readOnly={true} />
                  )}
                </div>
                <div className="col-lg-4 mt-1">
                  <Field
                    component={Input}
                    className={'filter-input'}
                    inputId="dataOcorrencia"
                    type={'date'}
                    name="dataOcorrencia"
                    label="Data de Ocorrência"
                    max="2999-12-31"
                    readOnly={condicaoPerformanceLastroEGarantia || (justView ? true : props.isEdit === undefined ? false : !eventoGenerico)}
                    onChange={(e_, v) => formataTituloDeAcordoComDataOcorrencia(v, props.tipoEvento, tipoContratoAditamentoLista, props.isEdit)}
                  />
                  <TextField
                    className="w-100 d-none inputDisabled"
                    disabled={true}
                    value={props.operacao == null ? '' : props.operacao.value}
                    readOnly={condicaoPerformanceLastroEGarantia || props.isEdit}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <Field
                    component={DropdownListInput}
                    className={'filter-input'}
                    inputId="tipoEvento"
                    name="tipoEvento"
                    label="Tipo do Evento"
                    options={tiposEventosSemGestaoFiduciaria(tiposEventos)}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={justView ? true : props.isEdit == undefined ? false : !eventoGenerico}
                    onChange={(e) => formataTituloDeAcordoComTipoEvento(e.descricao)}
                  />
                </div>

                {condicaoEspecieAssembleia && (
                  <div className="col-lg-4">
                    <Field
                      component={Input}
                      className={'filter-input'}
                      inputId="dataAssembleia"
                      type={'date'}
                      name="dataAssembleia"
                      label="Data da Assembléia"
                      max="2999-12-31"
                      readOnly={justView ? true : props.isEdit === undefined ? false : !eventoGenerico}
                      // onChange={(e) => formataTituloDeAcordoComDataOcorrencia(e.target.value, props.tipoEvento)}
                    />
                  </div>
                )}

                {campoEspecieComunicadoAssembleia && (
                  <div className="col-lg-4">
                    <Field
                      component={DropdownListInput}
                      className={'filter-input'}
                      inputId="idTipoEspecie"
                      name="composicaoTitulo.idTipoEspecie"
                      label="Espécie"
                      options={listOptions}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={justView ? true : props.isEdit == undefined ? false : !eventoGenerico}
                      onChange={(e) => formatacaoDoTituloConformeTiposEscolhidos(props.tipoEvento.descricao, e.descricao, campoFormulario)}
                    />
                  </div>
                )}

                {condicaoContratoAditamento && (
                  <>
                    <div className="col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="ordemContrato"
                        name="composicaoTitulo.ordemContrato"
                        label="Ordem do Contrato"
                        maxLength={2}
                        allowNegative={false}
                        readOnly={justView ? true : props.isEdit == undefined ? false : !eventoGenerico}
                        onChange={(e_, v) => formatacaoDoTituloTipoContrato(SeNaoTiverZeroPodeFormarOTituloDoContrato(v), tipoContratoAditamentoLista, props.isEdit)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Field
                        component={DropdownListInput}
                        className={'filter-input'}
                        inputId="idTipoContrato"
                        name="composicaoTitulo.idTipoContrato"
                        label="Contrato"
                        options={tipoContratoAditamentoLista}
                        textField="descricao"
                        valueField="id"
                        filter="contains"
                        readOnly={justView ? true : props.isEdit == undefined ? false : !eventoGenerico}
                        onChange={(e) => formatacaoDoTituloTipoContrato(props.ordemContrato, tipoContratoAditamentoLista, props.isEdit)}
                      />
                    </div>
                  </>
                )}

                {condicaoPerformanceLastroEGarantia && (
                  <div className="col-lg-4 col-xl-2">
                    <Field
                      component={Input}
                      className={'filter-input'}
                      inputId="dataBase"
                      type={'month'}
                      name="dataBase"
                      label="Data Base"
                      max="2999-12-31"
                      readOnly={justView ? true : props.isEdit === undefined ? false : !eventoGenerico}
                      onChange={(e_, v) => formataTituloDeAcordoComDataBase(v, props.tipoEvento)}
                    />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col">
                  <Field
                    component={Input}
                    className={'filter-input'}
                    inputId="titulo"
                    name="titulo"
                    maxLength={90}
                    type="text"
                    label="Título"
                    readOnly={justView ? true : props.isEdit == undefined ? (!tituloPreenchimentoTextoLivre ? true : false) : !eventoGenerico || !tituloPreenchimentoTextoLivre}
                    onChange={(e_, v) => digitacaoTextoLivre(props.tipoEvento.descricao, v)}
                  />
                </div>
                <div className="col-lg-4">
                  <Field component={Input} className={'filter-input'} inputId="link" name="link" maxLength={100} type="text" label="Link" readOnly={justView} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="f-20 fw-500 m-0 p-color-imp">Descrição</p>
                  <Field
                    component={Textarea}
                    outerClass="mt-2"
                    className={'filter-input'}
                    inputId="descricao"
                    name="descricao"
                    minHeight={8}
                    maxLength={10000}
                    type="text"
                    readOnly={justView}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="f-20 fw-500 m-0 p-color-imp">Anexar Arquivos</p>

                  <div
                    {...getRootProps()}
                    className="w-100 d-flex flex-column justify-content-center align-items-center text-center position-relative p-2 rounded my-2"
                    style={{
                      height: '200px',
                      transition: 'border 0.3s ease-out',
                      border: isDragging ? '2px dashed #630A37' : '2px dashed #cccccc',
                    }}
                  >
                    {!justView && (
                      <>
                        <input {...getInputProps()} />
                        <p className="py-2 mb-0">
                          Arraste os arquivos ou{' '}
                          <u className="btn-link-opea" role="button">
                            clique aqui
                          </u>{' '}
                          para upload
                        </p>
                      </>
                    )}
                    <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
                      {conteudoAnexoList?.length > 0 &&
                        conteudoAnexoList?.map((file, i) => {
                          return (
                            <div
                              key={`file-${i}`}
                              onClick={(e) => e.stopPropagation()}
                              style={{
                                backgroundColor: '#E7E7E7',
                                border: '1px solid #B8B8B8',
                              }}
                              className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                            >
                              <span>{file.nomeArquivo || file.name}</span>
                              <div className="d-flex flex-row">
                                {file?.id && (
                                  <i role="button" className="fa fa-cloud-download f-20 mr-3" onClick={async () => await buscarAnexoDiariodeBordoPorId({ idAnexo: file?.id })}></i>
                                )}

                                {!justView && <i role="button" onClick={() => onDelete(i)} className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"></i>}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="row mx-0 d-flex align-items-center justify-content-between py-3">
                <div>
                  <Button
                    name={!justView ? 'Cancelar' : 'Fechar'}
                    classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px"
                    action={(e) => {
                      e.preventDefault()
                      props.onCancel(false, false, 0)
                    }}
                  />
                </div>
                {!justView && <Button name="Salvar" classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px" action={handleSubmit} />}
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  )
}

CadastroEventosDiarioBordoForm = reduxForm({ form: 'cadastroDiarioBordoForm' })(CadastroEventosDiarioBordoForm)
const selector = formValueSelector('cadastroDiarioBordoForm')

const mapStateToProps = (state) => ({
  titulo: selector(state, 'titulo'),
  operacao: selector(state, 'operacao'),
  tipoEvento: selector(state, 'tipoEvento'),
  dataOcorrencia: selector(state, 'dataOcorrencia'),
  dataBase: selector(state, 'dataBase'),
  ordemContrato: selector(state, 'composicaoTitulo.ordemContrato'),
  tipoEspecieAssembleia: selector(state, 'composicaoTitulo.idTipoEspecie'),
  tipoEspecieComunicado: selector(state, 'composicaoTitulo.idTipoEspecie'),
  tipoContratoAditamento: selector(state, 'composicaoTitulo.idTipoContrato'),
  options: state.diarioBordoReducer.operacaoFormDiarioBordo,
  tiposEventos: state.diarioBordoReducer.diarioBoardFormTypes.tipoEventoDiarioBordoList || [],
  tipoEspecieAssembleiaLista: state.diarioBordoReducer.diarioBoardEspecieAssembleiaTypes.tipoEspecieAssembleiaList || [],
  tipoEspecieComunicadoLista: state.diarioBordoReducer.diarioBoardEspecieComunicadoTypes.tipoEspecieComunicadoList || [],
  tipoContratoAditamentoLista: state.diarioBordoReducer.diarioBoardContratosTypes.tipoContratoAditamentoList || [],
  eventoGenerico: selector(state, 'generico'),
  conteudoAnexoList: selector(state, 'diarioBordoAnexoList') || [],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      obterPreenchimentoTelaAlteracaoDiarioBordo,
      getOperacoes,
      getOperacoesFilter,
      onChangeFieldInput,
      formatacaoDoTituloConformeTiposEscolhidos,
      formatacaoDoTituloTipoContrato,
      formataTituloDeAcordoComDataOcorrencia,
      formataTituloDeAcordoComDataBase,
      formataTituloDeAcordoComTipoEvento,
      digitacaoTextoLivre,
      buscarAnexoDiariodeBordoPorId,
      changeValueForm,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEventosDiarioBordoForm)
