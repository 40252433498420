/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, formValueSelector, Field } from 'redux-form'
import GenericPickerInput from '../../../../../common/input/GenericPickerInput'
import { getOperacoes } from '../../../../../../actions/operacao/OperacaoAction'
import {
  onChangeFieldInput,
  onChanteTipoEvento,
  getDiariosBordo,
  resetFilters,
  showModalGenerico,
  saveEventoDiarioDeBordo,
} from '../../../../../../actions/gestao/DiarioBordoAction'

import '../assets/DiarioBordoListFilter.css'
import Input from '../../../../../common/input/Input'
import Form from '../../../../../common/form/Form'
import FormHeader from '../../../../../common/form/FormHeader'
import FormContent from '../../../../../common/form/FormContent'
import Button from '../../../../../common/button/Button'
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction'
import EventLogbookModal from '../modal/EventLogbookModal'
import CadastroEventosDiarioBordoForm from './CadastroEventosDiarioBordoForm'
import { CREATE, DIARIO_DE_BORDO } from '../../../../../../utils/actionsAndFeaturesAuth'

let DiarioBordoListFilter = (props) => {
  const [idStatus, setIdStatus] = React.useState([])
  const [counterStatus, setCounterStatus] = React.useState(props?.tiposEventos?.length || 0)

  const chooseStatus = (key) => {
    if (idStatus.includes(key)) {
      let newArrIds = idStatus.filter((elemento) => elemento !== key)
      setIdStatus(newArrIds)
    } else {
      setIdStatus([...idStatus, key])
    }
  }

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll('.diario-sts'))
    let arrStatus = []

    chips.forEach((el) => {
      if (!el.classList.contains('pe-none')) arrStatus.push(Number(el.dataset.status))
    })

    setIdStatus(arrStatus)
  }

  const checkStatus = (sts) => {
    return idStatus?.some((o) => o == sts.id) ? sts.cor : ''
  }

  const cleanStatus = () => {
    let arrStatus = []
    setIdStatus(arrStatus)
  }

  React.useEffect(() => {
    setCounterStatus(props?.tiposEventos?.length || 0)
  }, [props?.tiposEventos])

  React.useEffect(() => {
    props.onChangeFieldInput(idStatus, 'diarioBordoForm', 'idsDiarioBordoTipoEvento')
  }, [idStatus])

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={'Diário de Bordo'} />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
          <div className="row">
            <div className="col-lg-8 my-lg-0 mt-2 mb-2">
              <div className="mb-0 mt-2p">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">Operações</label>
                <GenericPickerInput
                  isClearable
                  id={'operacaoPicker'}
                  loadData={props.getOperacoes}
                  options={props.options}
                  formName={'diarioBordoForm'}
                  fieldName={'operacao'}
                  placeholder={'Pesquisar operação ex.:CRI.1.SEC.2'}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={props.operacao ? props.operacao : 'Pesquisar operação ex.:CRI.1.SEC.2'}
                  selectedOptionForm={(v) => props.onChangeFieldInput(v, 'diarioBordoForm', 'operacao')}
                />
              </div>
            </div>
            <div className="col-lg-2 mb-lg-2">
              <div>
                <Field component={Input} className={'filter-input'} inputId="dataInicio" type={'date'} name="dataInicio" label="Data Início" />
              </div>
            </div>
            <div className="col-lg-2 mb-lg-2">
              <div>
                <Field component={Input} className={'filter-input'} inputId="dataFim" type={'date'} name="dataFim" label="Data Fim" />
              </div>
            </div>
          </div>

          {props?.tiposEventos?.length > 0 && (
            <div className="row align-items-center px-3 my-1">
              <p className="mb-1 mr-2 fw-500">Tipos</p>
              <div className="px-2 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {props.tiposEventos.map((sts, index) => {
                  return (
                    <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                      <div role="button" onClick={() => chooseStatus(sts.id)} className="d-flex flex-row align-items-center td-status prevent-select">
                        <div
                          data-status={sts.id}
                          style={{
                            borderColor: `${checkStatus(sts)}`,
                            backgroundColor: `${checkStatus(sts)}`,
                          }}
                          className={`diario-sts circle-status rounded-circle mr-1`}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.descricao}</p>
                      </div>
                    </div>
                  )
                })}

                {idStatus.length < counterStatus && (
                  <span role="button" onClick={selecionarStatus} className="text-underline p-color-lighten mb-xl-1 ml-2">
                    Selecionar todos
                  </span>
                )}

                {idStatus.length == counterStatus && (
                  <span role="button" onClick={cleanStatus} className="text-underline p-color-lighten mb-xl-1 ml-2">
                    Desmarcar todos
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                name={'Buscar'}
                activeClassName="w-100"
                action={() => props.getDiariosBordo()}
                nameClass="text-capitalize f-14 lh-1 mt-1px"
                classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
              />
            </div>

            <div className="col-xl-2 col-12 px-0">
              <Button
                icon="plus-circle"
                name={'Criar Registro'}
                activeClassName="w-100"
                nameClass="mt-2px text-capitalize f-14"
                classes="darkblue-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                action={() => props.authorizationFunction({ idAcao: CREATE, idFuncionalidade: DIARIO_DE_BORDO }, () => props.showModalGenerico(true, false, 0))}
              />
            </div>
          </div>
        </FormContent>

        <EventLogbookModal
          title={'Tela de Cadastro - Diário de Bordo'}
          open={props.modalGeral.showDialogCadastro ? props.modalGeral.showDialogId === 0 : false}
          onClose={() => props.showModalGenerico(false, false, 0)}
        >
          <CadastroEventosDiarioBordoForm idOperacao={props.operacao} onSubmit={props.saveEventoDiarioDeBordo} onCancel={props.showModalGenerico} />
        </EventLogbookModal>
      </Form>
    </div>
  )
}

DiarioBordoListFilter = reduxForm({
  form: 'diarioBordoForm',
  initialValues: { idsDiarioBordoTipoEvento: [] },
})(DiarioBordoListFilter)
const selector = formValueSelector('diarioBordoForm')

const mapStateToProps = (state) => ({
  operacao: selector(state, 'operacao'),
  idsDiarioBordoTipoEvento: selector(state, 'idsDiarioBordoTipoEvento'),
  options: state.operacaoReducer.operacoes,
  modalGeral: state.diarioBordoReducer.showModalDialogGenerico,
  tiposEventos: state.diarioBordoReducer.diarioBoardFormTypes.tipoEventoDiarioBordoList || [],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOperacoes,
      onChangeFieldInput,
      onChanteTipoEvento,
      getDiariosBordo,
      resetFilters,
      showModalGenerico,
      authorizationFunction,
      saveEventoDiarioDeBordo,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DiarioBordoListFilter)
